import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const Home = () => import('@/views/Home')
const Introduction = () => import('@/views/Introduction')
const Intro1 = () => import('@/components/introduction/intro1.vue')
const Intro2 = () => import('@/components/introduction/intro2.vue')
const Intro3 = () => import('@/components/introduction/intro3.vue')
const Intro4 = () => import('@/components/introduction/intro4.vue')
const Intro5 = () => import('@/components/introduction/intro5.vue')
const Intro6 = () => import('@/components/introduction/intro6.vue')
const Intro7 = () => import('@/components/introduction/intro7.vue')
const News = () => import('@/views/News')
const News1 = () => import('@/components/news/news1.vue')
const News2 = () => import('@/components/news/news2.vue')
const Team = () => import('@/views/Team')
const Team1 = () => import('@/components/team/team1.vue')
const Team2_1 = () => import('@/components/team/team2_1.vue')
const Team2_2 = () => import('@/components/team/team2_2.vue')
const Team2_3 = () => import('@/components/team/team2_3.vue')
const Team2_4 = () => import('@/components/team/team2_4.vue')
const Team3_1 = () => import('@/components/team/team3_1.vue')
const Team3_2 = () => import('@/components/team/team3_2.vue')
const Team3_3 = () => import('@/components/team/team3_3.vue')
const Team3_4 = () => import('@/components/team/team3_4.vue')
const Team3_5 = () => import('@/components/team/team3_5.vue')
const Team4_1 = () => import('@/components/team/team4_1.vue')
const Team4_2 = () => import('@/components/team/team4_2.vue')
const Team5_1 = () => import('@/components/team/team5_1.vue')
const Team5_2 = () => import('@/components/team/team5_2.vue')
const Team5_3 = () => import('@/components/team/team5_3.vue')
const Team5_4 = () => import('@/components/team/team5_4.vue')
const Team5_5 = () => import('@/components/team/team5_5.vue')
const Team5_6 = () => import('@/components/team/team5_6.vue')
const Team5_7 = () => import('@/components/team/team5_7.vue')
const Team5_8 = () => import('@/components/team/team5_8.vue')
const Team5_9 = () => import('@/components/team/team5_9.vue')
const Team5_10 = () => import('@/components/team/team5_10.vue')
const Team6_1 = () => import('@/components/team/team6_1.vue')
const Team6_2 = () => import('@/components/team/team6_2.vue')
const Team7 = () => import('@/components/team/team7.vue')
const Team8 = () => import('@/components/team/team8.vue')
const Achivement = () => import('@/views/Achivement')
const Achive1 = () => import('@/components/achive/achive1.vue')
const Achive2 = () => import('@/components/achive/achive2.vue')
const Achive3 = () => import('@/components/achive/achive3.vue')
const Achive4 = () => import('@/components/achive/achive4.vue')
const Achive5 = () => import('@/components/achive/achive5.vue')
const Partyaffairs = () => import('@/views/Partyaffairs')
const Recruit = () => import('@/views/Recruit')
const Contact = () => import('@/views/Contact')

const routes = [
  {
    path: '',
    redirect: '/home'
  },
  {
    path:'/home',
    component:Home
  },
  {
    path:'/introduction',
    component:Introduction,
    children:[
      {
        path:'1',
        component:Intro1
      },
      {
        path:'2',
        component:Intro2
      },
      {
        path:'3',
        component:Intro3
      },
      {
        path:'4',
        component:Intro4
      },
      {
        path:'5',
        component:Intro5
      },
      {
        path:'6',
        component:Intro6
      },
      {
        path:'7',
        component:Intro7
      }
    ]
  },
  {
    path:'/news',
    component:News,
    children:[
      {
        path:'1',
        component:News1
      },
      {
        path:'2',
        component:News2
      }
    ]
  },
  {
    path:'/team',
    component:Team,
    children:[
      {
        path:'1',
        component:Team1
      },
      {
        path:'2-1',
        component:Team2_1
      },
      {
        path:'2-2',
        component:Team2_2
      },
      {
        path:'2-3',
        component:Team2_3
      },
      {
        path:'2-4',
        component:Team2_4
      },
      {
        path:'3-1',
        component:Team3_1
      },
      {
        path:'3-2',
        component:Team3_2
      },
      {
        path:'3-3',
        component:Team3_3
      },
      {
        path:'3-4',
        component:Team3_4
      },
      {
        path:'3-5',
        component:Team3_5
      },
      {
        path:'4-1',
        component:Team4_1
      },
      {
        path:'4-2',
        component:Team4_2
      },
      {
        path:'5-1',
        component:Team5_1
      },
      {
        path:'5-2',
        component:Team5_2
      },
      {
        path:'5-3',
        component:Team5_3
      },
      {
        path:'5-4',
        component:Team5_4
      },
      {
        path:'5-5',
        component:Team5_5
      },
      {
        path:'5-6',
        component:Team5_6
      },
      {
        path:'5-7',
        component:Team5_7
      },
      {
        path:'5-8',
        component:Team5_8
      },
      {
        path:'5-9',
        component:Team5_9
      },
      {
        path:'5-10',
        component:Team5_10
      },
      {
        path:'6-1',
        component:Team6_1
      },
      {
        path:'6-2',
        component:Team6_2
      },
      {
        path:'7',
        component:Team7
      },
      {
        path:'8',
        component:Team8
      }
    ]
  },
  {
    path:'/achivement',
    component:Achivement,
    children:[
      {
        path:'1',
        component:Achive1
      },
      {
        path:'2',
        component:Achive2
      },
      {
        path:'3',
        component:Achive3
      },
      {
        path:'4',
        component:Achive4
      },
      {
        path:'5',
        component:Achive5
      }
    ]
  }, {
    path:'/partyaffairs',
    component:Partyaffairs
  },
  {
    path:'/recruit',
    component:Recruit
  },
  {
    path:'/contact',
    component:Contact
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router