<template>
  <div id="app" ref="app">
    <router-view></router-view>
    <BackTop />
  </div>
</template>

<script>
export default {
  name: 'App',
  watch: {
    $route() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
  },
}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}
#app {
  background-color: #f0f0f0;
}
@import url('./assets/css/icon.css');
.box {
  padding: 20px 20% 20px;
  background: url('./assets/img/logo.png') no-repeat;
  background-size: 60% auto;
  background-position: right bottom;
}

.about-title {
  padding: 30px 0 20px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.block-line {
  margin: 0 auto 12px;
  width: 24px;
  height: 4px;
  background-color: #3370ff;
  border-radius: 100px;
}

.about-container {
  min-height: 400px;
  margin: 0 auto;

  .about-card {
    padding-top: 40px;
    margin: 0 auto 50px auto;
    display: flex;
    justify-content: space-evenly;
    //background-color: #d3dce6;
    .el-card {
      width: 280px;
      height: 160px;
      overflow: hidden;
      color: #FFFFFF;
    }

    .about-card-item {
      width: 280px;
      height: 160px;
      position: relative;
      cursor: pointer;

      .item-mask {
        position: absolute;
        background: linear-gradient(transparent, #030822);
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 50px 20px;
        height: 97px;
        box-sizing: border-box;
      }

      span {
        color: #fff;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }

    .about-card-item:hover img {
      transform: scale(1.02);
    }

    .el-card__body {
      padding: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all .4s ease-in-out;
    }
  }

  .about-list {
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  .about-list:after {
    content: "";
    position: absolute;
    left: 0;
    top: 43px;
    width: 100%;
    height: 2px;
    background-color: #E4E7ED;
    z-index: 1;
  }

  .el-tabs__header {
    height: 60px;
  }

  .el-tabs__active-bar {
    bottom: 5px;
    //height: 3px;
  }

  .el-tabs__item {
    height: 50px;
    line-height: 50px;
    font-weight: 600;
  }

  .list-left {
    width: 860px;

    .el-tabs__content {
      height: auto;
      //background-color: #d2d3d4;
    }
  }

  .el-pagination {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 50px;
  }

  .list-right {
    margin-left: 50px;

    .search-by-date {
      //padding: 6px 0;
      display: flex;
      height: 50px;
      align-items: center;
      justify-content: flex-end;
      font-size: 14px;
      margin-bottom: 25px;

      p {
        white-space: nowrap;
        position: relative;
        //right: -30px;
      }

      .el-date-editor.el-input {
        width: 160px;
      }

      .el-input__inner {
        width: 160px;
        height: 30px;
        line-height: 30px;
      }

      .el-input__prefix, .el-input__suffix {
        top: -4px;
      }

      //.el-input__suffix{
      //  right: 50px;
      //}
    }

    .el-card__body {
      padding-top: 0;
    }

  }
  .news-list {
    margin-top: 15px;
    .list-item {
      cursor: pointer;
      display: flex;
      padding: 16px 0;
      position: relative;
      color: #606266;
      font-size: 16px;
      transition: all .3s;
      border-bottom: 1px dashed #909399;
      .time {
        display: inline-block;
        width: 85px;
        text-align: right;
      }
      .title {
        display: inline-block;
        width: calc(100% - 85px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .list-item:hover {
      color: #0b1660;
      opacity: 0.8;
    }
  }
}
</style>
